import { ChakraProvider, ColorModeProvider, DarkMode, Box, Button, Image, Link, Tooltip, Text, Heading, Input, Textarea, List, ListItem, ListIcon, Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  UnorderedList,
  OrderedList, SimpleGrid, Card, CardHeader, CardBody, CardFooter, Stack, StackDivider, 
  Divider,
  Badge} from "@chakra-ui/react";
import logo from './imgs/logo2.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";

import theme from "./components/ChakraConfig"
import "./custom.css"

import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import BTTop from "./components/BackToTop";
import { CheckIcon, CheckCircleIcon, StarIcon } from "@chakra-ui/icons";
import HubspotForm from "./components/ChallengeFormHubspot";



function Header() {
  return(
    <Box className="head" p={10} pt={2} mt={5} id="head" position='relative' >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Link as="a" href="/"><Image src={logo} title="Logo" alt="BlooAstra Logo" width={180} /></Link>
      </Box>
      <Link href="/" position='absolute' top='7px' textDecoration="none" color="hsl(0deg 0% 100% / 50%)">
        <Tooltip label='Back Home' placement='right' color="hsl(0deg 0% 100% / 50%);" >
          <FontAwesomeIcon icon={faAngleLeft} size='2xl' />
        </Tooltip>
      </Link>
    </Box>
  )
}

function Titles() {
  return(
    <Box className="copy" my={20} maxWidth="750px" mx="auto">
      {/* Heading */}
      <Box className="titles" textAlign='center'>
        <Text as='h1' className="title" fontSize="5xl" bgClip="text" maxW={700} textAlign="center" lineHeight="1em" mb={5} mx='auto' color='white' >Unlock Your Store’s Full Potential with Web Accessibility Solutions</Text>
        <Text fontSize="xl" mt={5}>Empowering Shopify Merchants to Reach More Customers, Improve User Experience, and Stay Legally Compliant</Text>
        {/* <Text  fontSize="xl" mt={5}><em>...and get to write off the cost when doing your taxes!</em></Text> */}
        <VSL />
        <Button as='a' mt={4} paddingX={10} paddingY={6} href='#pricing' variant='outline' colorScheme='green'>Select A Package</Button>
      </Box>
    </Box>
  )
}

function VSL() {
  return(
    <Box className="vsl" my={10} maxWidth="900px" mx="auto" >
      <Box className="" mt={2} minHeight="350px" id="" padding='2em'></Box>
    </Box>
  )
}

function Copy() {
  return(
    <Box className="details" my={20} maxWidth="900px" mx="auto" >
      <Box className="howitworks" mt='10em' id="howitworks" padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h3" textAlign='center'>Why Accessibility Matters for Your Shopify Store</Heading>
        <Text>In today’s digital age, having an accessible website is no longer just a nice-to-have — <strong>it’s a necessity</strong>. Not only does web accessibility improve the shopping experience for all users, <strong>it also ensures that your store meets legal compliance standards</strong>, such as the ADA (Americans with Disabilities Act) and WCAG (Web Content Accessibility Guidelines). By optimizing your Shopify store for accessibility, you open your business to millions of potential customers who rely on assistive technologies to browse and shop online.</Text>
        <Text>Here are some eye-opening stats (US specific):</Text>
        <Text><CheckCircleIcon mx={3} color='green.500' /><strong>Nearly 26% of adults in the U.S. have some form of disability</strong> that can affect their digital experience. This group represents a substantial market with a significant online presence.</Text>
        <Text><CheckCircleIcon mx={3} color='green.500' /><strong>56% of disabled individuals in the U.S. shop online at least once a week</strong>, compared to 22% of the general population and accessibility is a critical factor for disabled users when choosing an online store.</Text>
        <Text><CheckCircleIcon mx={3} color='green.500' /><strong>36% of disabled users reported opting for more accessible websites, <span style={{textDecoration: 'underline'}}>even if they were more expensive.</span></strong></Text>
        <Text><CheckCircleIcon mx={3} color='green.500' /><strong>24% of Baby Boomers (aged 65-74) in the U.S. reported a disability.</strong> This age group <strong>controls about 70% of the nation’s wealth</strong>, indicating their importance as a market segment for accessible websites.</Text>

        <Heading as="h3" textAlign='center'>I can help you tap into this market</Heading>
        <Text>With over 8 years of Shopify development experience and a deep understanding of accessibility standards, I specialize in helping Shopify merchants make their stores more inclusive. I’ve seen firsthand how an accessible store not only boosts user engagement but also protects your brand from legal risks while improving your store’s SEO and conversion rates.</Text>
        <Text>Here's how I will help achieve this:</Text>
        <Text><StarIcon mx={3} color='green.500' /></Text>
        <UnorderedList>
          <ListItem><strong>Comprehensive Accessibility Audits</strong><br/>
          I’ll thoroughly review your Shopify store, identifying barriers and providing actionable recommendations to meet WCAG standards.</ListItem>
          <ListItem><strong>Hands-On Store Optimization</strong><br/>
          From adjusting code to making design changes, I’ll work directly with you to implement improvements that make your site accessible to all users, without compromising performance or aesthetics.</ListItem>
          <ListItem><strong>Accessibility-First Development for New Features</strong><br/>
          Whether launching a new product page or redesigning a store, I’ll ensure that every feature is accessible from day one.
          </ListItem>
          <ListItem><strong>Legal Compliance Support</strong><br/>
          I’ll help you avoid costly lawsuits by ensuring your store adheres to relevant legal standards, providing peace of mind for your business.</ListItem>
        </UnorderedList>

        <Heading as="h3" textAlign='center'>What are the benefits of having your store optimized and compliant?</Heading>
        <OrderedList>
          <ListItem>Expand Your Market<br/>
          Reach customers with disabilities, representing over $1.2 trillion in annual purchasing power worldwide.</ListItem>
          <ListItem>Boost Your SEO<br/>
          Accessible websites are often better optimized for search engines, helping improve your rankings on Google and other search platforms.</ListItem>
          <ListItem>Enhance User Experience<br/>
          Accessibility improvements make your store easier to navigate for everyone, increasing conversion rates and overall satisfaction.</ListItem>
          <ListItem>Stay Legally Compliant<br/>
          Protect your business from potential lawsuits and fines by ensuring your store meets ADA and WCAG guidelines.</ListItem>
        </OrderedList>

      </Box>
    </Box>
  )
}

function FAQs() {
  return(
    <Box className="faqs" id='faqs' my={20} maxWidth="900px" mx="auto" >
      <Box background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h2" textAlign='center'>Frequently Asked Questions:</Heading>
        <Accordion mt={5}>
          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How many tasks can I submit?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: You can submit as many as you want but first priority will be given to queue of merchants then queue of tasks.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How do I submit multiple tasks?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: Simply include them in the same entry. That's what we recommend so we can get to all your tasks as soon as possible.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: What’s the catch?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: There’s no catch! We are doing this to help the Shopify community, create awareness about us, and build our brand.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: How will I know if my task is selected?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: We’ll notify you via email if your task is selected for the day.
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem>
            <Text>
              <AccordionButton>
                <Box as='span' flex='1' textAlign='left' fontSize='xl' fontWeight='bold'>
                  Q: Can I request a specific day for my task?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </Text>
            <AccordionPanel pb={4}>
              A: Unfortunately, I can’t guarantee specific days for tasks. Tasks will be selected based on various factors including feasibility and impact. But you can reach out to us by <Link href="/#contact" textDecoration='underline' target="_blank"><strong>clicking here</strong></Link> or checking out <Link href="/#services" textDecoration='underline' target="_blank"><strong>our services here</strong> </Link> if you need priority support/assistance.
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
        <Box textAlign='center' mt={10}><Button as='a' paddingX={10} paddingY={6} href='#pricing' variant='outline' colorScheme='green' mb={10}>Select A Package</Button></Box>
      </Box>
    </Box>
  )
}

function Process() {
  return(
    <Box className="process" id="process" my={20} maxWidth="900px" mx="auto" >
      <Box mt='10em' padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h3" textAlign='center'>My process to efficiently getting this done:</Heading>
        <Text>Here's how I make improving your store’s accessibility simple and effective with a clear, step-by-step process designed for Shopify merchants.</Text>
        <OrderedList>
          <ListItem>Consultation & Initial Assessment
          During our initial consultation, we’ll discuss your current Shopify store, specific accessibility concerns, and your business goals. I’ll perform a preliminary review of your site to identify any obvious issues and provide an overview of potential solutions.</ListItem>
          <ListItem>Accessibility Audit & Strategy Development
          Based on your chosen service plan (Basic, Essentials, or Full Service), I’ll conduct a comprehensive accessibility audit of your store. This includes reviewing code, design elements, and user flows to uncover barriers for people using assistive technologies. Afterward, I’ll provide a detailed report outlining the issues found and the steps required to fix them.</ListItem>
          <ListItem>Implementation & Optimization
          Once we agree on the strategy, I’ll get to work implementing the necessary updates. Depending on your service plan, this could include coding fixes, design adjustments, improved navigation, or accessibility-first feature development. I’ll ensure that all changes align with WCAG 2.1 and other relevant standards.</ListItem>
          <ListItem>Review & Testing
          After the initial changes are implemented, I’ll rigorously test your Shopify store to ensure that all improvements work seamlessly with assistive technologies like screen readers, keyboard navigation, and more. I’ll also gather feedback from users with disabilities, if applicable.</ListItem>
          <ListItem>Ongoing Support & Monitoring (Full Service)
          If you’ve opted for the Full Service Plan, I’ll provide continuous monitoring and support to ensure your store stays accessible over time. This includes regular audits, updates for new content, and guidance on accessibility for future store features or redesigns.</ListItem>
        </OrderedList>
        <Box textAlign='center' mt={10}><Button as='a' paddingX={10} paddingY={6} href='#pricing' variant='outline' colorScheme='green'>Select A Package</Button></Box>
      </Box>
    </Box>
    
  )
}

function Disclaimer() {
  return(
    <Box className="disclaimer" maxWidth='900px' mx='auto' pb={20}>
      <Text><strong>Disclaimer: </strong>Submitting an entry doesn't guarantee that we'll treat it as although we'll try to go through as many entries as come in, we'll need to attend to them on a first-come first-serve basis.</Text>
      <Text>Also, some task details submitted will have to be broken down into separate tasks which means your entry might get treated in batches.</Text>
      <Text>Please read the <Link href="#guidelines" textDecoration='underline'><strong>guidelines</strong></Link> for better understanding.</Text>
    </Box>
  )
}


function Form() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    store: '',
    task: '',
  });

  const [responseMessage, setResponseMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    setTimeout(() => {
      emailjs.send(
        'service_wo67jv2',
        'template_4vdkbjc',
        formData,
        'C3C17ZEDmcmpIEaiq',
      ).then((response) => {
        setResponseMessage('Entry submitted successfully!');
        setFormData({ name: '', email: '', phone: '', store: '', task: '', });
        window.location.href="/cropromo";
      }).catch((error) => {
        setResponseMessage('There was an error submitting your entry. Please try again later.');
        setIsSubmitting(false);
      });
    }, 2000);

    
  };
  return(
    <Box minHeight="30vh" p={10} as="section" className="contact" id="form" bgColor="black.500">
      <Box maxWidth="700px"  my={20} mx="auto" textAlign="center" background='rgba(0, 0, 0, 0.5)' padding='3em' borderRadius='25px'>
        <Heading as="h2">Submit Task</Heading>
        <Text fontSize='xl'>Take advantage of this unique opportunity to get free expert help for your Shopify store.<br/>Don’t miss out – submit your task today!</Text>
        <form onSubmit={handleSubmit} style={{margin: "1em 0"}} className="gradient-border" id="sbform">
          <Input placeholder='Your name' size='sm' type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder="email@domain.com"
            size='md'
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder="+1 000 000 0000"
            size='md'
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            variant='filled'
            required />
          <Input placeholder='Store URL' size='md' type="text"
            id="store"
            name="store"
            value={formData.store}
            onChange={handleChange}
            variant='filled'
            required />
          <Textarea placeholder='Task details'
            size='md'
            resize='vertical' 
            id="task"
            name="task"
            value={formData.task}
            onChange={handleChange}
            variant='filled'
            required rows={6} />
          <Button variant='solid' colorScheme='green' type="submit" style={{marginTop: "1em"}} isLoading={isSubmitting} loadingText='Submitting'>Submit Task Entry</Button>
          {responseMessage && <p>{responseMessage}</p>}
        </form>
      </Box>
    </Box>
  )
}

function Pricing() {
  return(
    <Box className="pricing" id="pricing" my={20} maxWidth="900px" mx="auto">
      <Box mt='10em' padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px">
        <Heading as="h3" textAlign='center'>Ready to Make Your Shopify Store Accessible?</Heading>
        <Text textAlign='center'>Choose the Right Accessibility Plan for Your Shopify Store</Text>
        <Text>Whether you’re looking to meet basic compliance standards or fully optimize your store for accessibility, I offer tailored solutions to fit your needs.</Text>
      </Box>
      <SimpleGrid columns={{ sm: 1, md: 1, lg: 3 }} spacing='8' textAlign='left' rounded='lg' p={10} maxW="1200px" mx="auto">
        <Card boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0" position='relative' height='max-content'>
          <Badge variant='solid' colorScheme='#e9d8fd' color='black' position='absolute' top='-0.5rem' backgroundColor='#e9d8fd'>Basic</Badge>
          <CardHeader padding={0}  mb="1rem">
            <Heading as='p' size='sm' textTransform='uppercase'>Basic Plan:</Heading>
            <Heading as='p' size='md' py={0}>Compliance Starter</Heading>
            <Text pt='2' fontSize='sm'>
            Get your Shopify store compliant with essential web accessibility standards.
            </Text>
          </CardHeader>
          <CardBody padding={0} >
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <List mb="1rem" styleType='none'>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>WCAG 2.1 A/AA compliance audit</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Accessibility review & optimization of key pages (Home, Product, Checkout)</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Detailed report with action steps</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Basic alt text recommendations for images</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Accessibility statement & page preparation</ListItem>
            </List>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <Text fontWeight="bold">Perfect for:</Text>
            <Text mb="1rem" fontSize='sm'>New Shopify stores looking to address foundational accessibility needs and avoid basic legal risks.</Text>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
          </CardBody>
          <CardFooter>
            <Button variant='solid' colorScheme='green'>
              Buy now&nbsp;<span style={{textDecoration: 'line-through'}}>$149</span>&nbsp;$49
            </Button>
          </CardFooter>
        </Card>
        
        <Card boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0" position='relative' height='max-content'>
          <Badge variant='outline' colorScheme='green' position='absolute' top='-0.5rem' backgroundColor='black'>Most Purchased</Badge>
          <CardHeader padding={0}  mb="1rem">
            <Heading as='p' size='sm' textTransform='uppercase'>Essentials Plan:</Heading>
            <Heading as='p' size='md' py={0}>User Experience & Compliance Boost</Heading>
            <Text pt='2' fontSize='sm'>
            Go beyond compliance and improve the overall user experience on your store.
            </Text>
          </CardHeader>
          <CardBody padding={0} >
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <List mb="1rem" styleType='none'>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Everything in the Basic Plan</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Full-site accessibility audit and fixes</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Accessible navigation and form enhancements</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Captioning recommendations for media content</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Improved contrast and text readability</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Detailed UX recommendations to improve usability for all</ListItem>
            </List>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <Text fontWeight="bold">Perfect for:</Text>
            <Text mb="1rem" fontSize='sm'>Growing businesses ready to invest in a more inclusive shopping experience.</Text>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
          </CardBody>
          <CardFooter>
            <Button variant='solid' colorScheme='green'>
              Buy now&nbsp;<span style={{textDecoration: 'line-through'}}>$149</span>&nbsp;$49
            </Button>
          </CardFooter>
        </Card>
        <Card boxShadow='lg' p='6' rounded='md' _hover={{boxShadow:'xl',}} bg='blackAlpha.700'  border={1} display='flex' flexDirection='column' alignItems='center' style={{borderRadius: '25px', border: '1px solid rgba(255, 255, 255, 0.7)'}} tabindex="0" position='relative' height='max-content'>
          <Badge variant='solid' colorScheme='green' color='black' position='absolute' top='-0.5rem' backgroundColor='rgb(56 161 105)'>Recommended</Badge>
          <CardHeader padding={0}  mb="1rem">
            <Heading as='p' size='sm' textTransform='uppercase'>Full Service Plan:</Heading>
            <Heading as='p' size='md' py={0}>Comprehensive Optimization</Heading>
            <Text pt='2' fontSize='sm'>
            Get your Shopify store compliant with essential web accessibility standards.
            </Text>
          </CardHeader>
          <CardBody padding={0} >
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <List mb="1rem" styleType='none'>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Everything in the Essentials Plan</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Continuous monitoring and updates for accessibility</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Advanced ARIA markup for screen reader compatibility</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Ongoing support for accessibility improvements</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Development of accessibility-first features</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Accessibility-focused testing for new store additions or redesigns</ListItem>
              <ListItem><ListIcon as={CheckCircleIcon} color='green.500'/>Legal compliance support and documentation</ListItem>
            </List>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
            <Text fontWeight="bold">Perfect for:</Text>
            <Text mb="1rem" fontSize='sm'>Large-scale Shopify stores or those looking to maintain long-term accessibility and avoid future risks.</Text>
            <Divider variant='solid' borderColor="grey" mb="1rem" />
          </CardBody>
          <CardFooter>
            <Button variant='solid' colorScheme='green'>
              Buy now&nbsp;<span style={{textDecoration: 'line-through'}}>$149</span>&nbsp;$49
            </Button>
          </CardFooter>
        </Card>
      </SimpleGrid>
      <Box padding='2em' background='rgba(0, 0, 0, 0.5)' borderRadius="25px"><Text>No matter which plan fits your needs, improving your Shopify store’s accessibility is an investment in your customers, your brand, and your business growth. Ready to take the next step?</Text></Box>
      
    </Box>
  );
}

// With a VSL:
// - Heading
// - Video 
// - Button
// - Benefits
// - Pricing
// - FAQ
// - CTA

function Access() {
  return (
    <ChakraProvider theme={theme}>
      <ColorModeProvider  colorMode="dark" initialColorMode={theme.config.initialColorMode}>
        <DarkMode>
        <Box mx='auto' backgroundColor='rgba(0, 0, 0, 0.25)'>
          {/* <Header /> */}
          <Titles />
          <Copy />
          <Pricing />
          <Process />
          <FAQs />
          {/* <Form /> */}
          {/* <HubspotForm />*/}
          {/* <Disclaimer /> */}
          <BTTop />
        </Box>
        </DarkMode>
      </ColorModeProvider>
    </ChakraProvider>
  );
}

export default Access;




// Headline


// What is Web Accessibility Compliance of those unaware?


// What benefits does this have for you and your business?


// At this point, you can't say you're not aware, so refusing to comply puts you at great risk.

// Here are some people and brands and the fines and settlement fees they had to shell out:
// 1)
// 2)
// 3)

// I've highlighted mostly large brands but the truth is small and medium sized businesses take the greatest hits as they are of greater number and the cost of litigation fees, settlement, and fines can bankrupt you.

// Here are some stats to buttress my point:
// 1)
// 2)
// 3)

// On that note, I strongly recommend you take some form of action today. I've made it as easy as possible by providing you three options on how to proceed. Take a look at the pricing and pick the one you think is best for you right now.

// 149 - 3 days turnaround time
// 449 - 10 days turn around time
// 7620 - every day for 6 months (6 monthly installments of 1270)


// Accessibility statement tailored to your brand and ensuring there are no legal liabilities for your business.
// Main theme layout
// Header, footer sections
// Homepage sections (i.e sections currently in use on your homepage)
// Homepage snippets (i.e snippets currently in use on your homepage)
// Product page(s) templates (defaults and customs as applicable)
// Collections template
// Blog template


// Page templates (defaults and customs as applicable)
// Product page(s) templates (defaults and customs as applicable)

// Dynamically rendered store aspects
// main theme Layout
// custom layout
// Templates
// Sections
// Snippets
// Page templates (defaults and customs as applicable)
// Product page(s)templates (defaults and customs as applicable)
// Collection templates (defaults and customs as applicable)
// Collections templates (defaults and customs as applicable)
// Blog template (default and customs as applicable)
// Cart template (default and custom as applicable)
// Article template (default and custom as applicable)
// Search template (default and custom as applicable)
// Gift card template (default and custom as applicable)

// Static store aspects
// Product descriptions
// Pages contents
